.waitlist-signup-container {
    text-align: center;
    border: 2px solid #aaa;
    margin-top: 20px;
    border-radius: 5px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    padding-block: 80px;
    width: 40%;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
}

.name-container {
    padding: 5px;
}

.email-container {
    padding: 5px;
}

.submit-container {
    padding: 5px;
}
