.calltoaction {
    width: 100%;
    height: 100vh;
    color: #fff;
    position: relative;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

#image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.calltoaction .content {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 1rem;
}

@media screen and (max-width: 940px) {
    .content {
        display: flex;
    }
}
