.register-form-container {
    width: 300px;
    margin: 0 auto;
    padding: 1em;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.register-form-container h2 {
    text-align: center;
    margin-bottom: 1em;
}

.form-group {
    margin-bottom: 1em;
}

.form-group label {
    display: block;
    margin-bottom: 0.5em;
}

.form-group input {
    width: 100%;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error {
    color: red;
    font-size: 0.8em;
}

.text {
    color: #ccc;
}

/* button {
    width: 100%;
    padding: 0.7em;
    background: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
} */

/* button:hover {
    background: #0056b3;
} */
