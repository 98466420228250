.navbar {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    z-index: 2;
    position: absolute;
    color: #fff;
    background-color: #333; /* Adjusted for better visibility */
}

.logo {
    flex: 1; /* Allow the logo to take up space */
    text-align: left;
}

.nav-menu {
    display: flex;
    flex: 2; /* Allow the nav menu to take up space */
    justify-content: center;
}

li {
    padding: 0 1rem;
    list-style: none;
}

.links {
    color: #fff;
    text-decoration: none;
}

.nav-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1; /* Allow the icons to take up space */
    justify-content: flex-end;
}

.hamburger {
    display: none;
}

.mobile-menu {
    display: none;
}

.icon {
    font-size: 1.5rem;
    cursor: pointer;
}

/* New styles for icon alignment */
.icon-container {
    display: flex;
    align-items: center;
}

.custom-dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 2.5rem;
    right: 0;
    background-color: rgb(0, 0, 0, 0.5);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    z-index: 10;
}

.dropdown-menu li {
    padding: 0.5rem 1rem;
}

.dropdown-menu li a {
    color: #fbfafa;
    text-decoration: none;
}

.dropdown-menu li:hover {
    background-color: rgb(0, 0, 0, 0.9);
}

/* Media Query for smaller screens */
@media screen and (max-width: 940px) {
    .mobile-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        top: -100vh;
        left: 0;
        position: absolute;
        justify-content: space-between;
        background-color: #f5f5f5;
        transition: 0.3s;
        color: #000;
    }

    .mobile-links {
        color: #000;
    }

    .dark {
        color: #000;
    }

    .active {
        top: 80px;
    }

    .mobile-menu li {
        padding: 1.2rem 1rem;
        margin: 0 1rem;
        border-bottom: 1px solid #ccc;
    }

    .mobile-nav {
        margin: 1rem 0;
    }

    .mobile-menu-bottom {
        width: 100%;
        text-align: center;
        padding: 1rem 0;
    }

    .social-icons {
        display: flex;
        justify-content: space-around;
        margin: 2rem 0;
    }

    .social-icons .icon:hover {
        transform: scale(1.5);
        transition: 0.3s;
    }

    .mobile-menu button {
        margin: 1rem;
        width: 90%;
    }

    .nav-menu {
        display: none;
    }

    .nav-icons {
        display: none;
    }

    .hamburger {
        display: block;
    }
}
