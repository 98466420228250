@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-dark: #6e6af3;
  --primary-light: #8daef4;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2rem;
}

.container {
  max-width: 1240px;
  margin: auto;
  padding: 0 1rem;
}

button {
  background-image: linear-gradient(45deg, var(--primary-dark) 0%, var(--primary-light) 100%);
  border: 1px solid var(--primary-dark);
  color: #fff;
  padding: .7rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

/* button:hover {
  background-image: #1d2571;
} */

.custom-file-upload {
  background-color: rgb(0, 0, 0, 0.5);
  border: 1px solid #ccc;
  color: #fff;
  border-radius: 8px;
  padding: .7rem 1.5rem;
  font-family: 'Poppins', sans-serif;
  margin-right: 10px;
}

.custom-file-upload:hover {
  background-color: rgb(0, 0, 0, 0.9);
  cursor: pointer;
}

@media screen and (max-width: 940px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}
