.footer {
    background-color: black;
    color: white;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.links {
    padding: 0;
    margin: 0;
    list-style: none;
}

.links ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.links ul li {
    margin: 0 15px;
    padding: 5px 0;
}

.click-links {
    color: white;
    text-decoration: none;
}

.click-links:hover {
    text-decoration: underline;
}

.social-icons {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.social-icons .icon {
    margin: 0 10px;
    cursor: pointer;
}

.social-icons .icon:hover {
    transition: 0.3s;
}

.copyright, .privacy-policy {
    color: grey;
    margin: 10px 0;
    text-align: center;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    .links ul {
        flex-direction: column; /* Stack links vertically */
    }

    .links ul li {
        margin: 10px 0;
    }

    .social-icons {
        margin: 1rem 0; /* Reduce margin for smaller screens */
    }
}
